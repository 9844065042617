import mixins from '@/mixins'
import course from '@/mixins/course'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'courses-content',
  mixin: [mixins, course],
  data () {
    return {
      activeTab: ''
    }
  },
  computed: {
    ...mapGetters({
      currentCourse: 'coursesInfo/info',
      courseModules: 'coursesSections/courseModules'
    }),
    tabs () {
      return [
        {
          name: 'Studying ',
          slug: 'studying',
          component_id: 2
        },
        {
          name: 'Timetable',
          slug: 'timetable',
          component_id: 3
        },
        {
          name: 'Streaming',
          slug: 'streaming',
          component_id: 4
        },
        {
          name: 'Q/A sessions',
          slug: 'qa-sessions',
          component_id: 5
        },
        {
          name: 'Meetups',
          slug: 'meetups',
          component_id: 6
        },
        {
          name: 'Bonuses',
          slug: 'bonuses',
          component_id: 7
        },
        {
          name: 'Groups in social networks',
          slug: 'socials',
          component_id: 8
        }
      ]
    }
  },
  created () {
    this.setCourseStep(5)
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
      setStreamItem: 'contentStreaming/SET_ITEM',
      setStreamList: 'contentStreaming/SET_LIST',
      setTimetableList: 'contentTimetable/SET_LIST'
    }),
    isSelectedComponent (item) {
      if (!this.currentCourse && !this.currentCourse.components.data) return
      return this.courseModules.find(e => e.component_id === item.component_id)
    }
  },
  destroyed () {
    this.setStreamItem(null)
    this.setStreamList([])
    this.setTimetableList([])
  }
}
